.svg {
    display: block;
    margin: 20px auto;
    max-width: 100%;
}

.svg-circle-bg {
    fill: none;
}

.svg-circle {
    fill: none;
}
.svg-circle-text {
   font-size: 3rem;
    text-anchor: middle;
    color: #000330;
    font-weight: bold;
}